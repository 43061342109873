<template lang="html">
  <div class="">
    <div  v-if="this.loading"   :style="{width: '100%',zIndex: 2,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'0%', height: '100%' }">
    </div>
    <div  v-if="this.loading"  :style="{width: '70%',backgroundColor:'white' ,borderRadius: '67px', border: '5px solid #11BA6C',color:'#11BA6C', zIndex:'6',position:'fixed',marginLeft:'14%',fontSize:'25px', paddingTop:'5%',marginTop:'2%', height: '42%'}">
             <div :style="{width:'80%', marginLeft:'10%'}">
                Uppdaterar profil ... <br> Vänligen vänta...det kan ta några minuter
              </div>
    </div>


    <div class="" id="mapholder" :style="{height:'40px', width:'90%'}">
    </div>

    <div class="">
      <!-- <a :href="'tel:'+ this.phone_number_test.toString() " :style="{height:'20px', width:'20px'}">gdxg </a>
      <div class="" @click="window.open('tel:+46739569253');">
        test
      </div> -->
      <div :style="{fontWeight:'bold',marginTop:'2%', marginLeft:'2%',textAlign:'left',fontSize:'22px', height:'29px',color: '#11BA6C'}">
        Välj företag
      </div>
      <div class="input_box_input_class2">
        <input type="text" class="input_box_input" v-model="search_company"  placeholder="Välj företag" v-on:keyup="load_company_func(search_company)" >
      </div>
      <div :style="{marginTop:'1%',border: '2px solid #11BA6C', borderRadius:'8px',marginLeft:'4%',width:'90%',height:'120px', overflowY:'auto'}">
        <div v-for="(company,idx) in company_list" :key="idx" :style="{marginLeft:'1%' ,textAlign:'left',  width:'90%',height:'15px', fontSize:'14px', marginTop:'0%'}">
          <div :style="{width:'100%',height:'15px', display:'flex',marginTop:'0%'}" @click="choose_company(company)">
            <div :style="{marginLeft:'1%',marginTop:'0%'}">
              {{company.name}} {{company.id}}
              <b-icon  v-if="company.inladdad" :style="{backgroundColor:'green'}"  icon="check" ></b-icon>
              <b-icon  v-if="company.verified==1" :style="{backgroundColor:'blue'}"  icon="check" ></b-icon>
            </div>
          </div>
        </div>
      </div>
      <div  :style="{height:'5px',width:'80%',marginLeft:'1.5%', marginTop:'2%' ,textAlign:'left'}" >
        <div :style="{fontSize:'22px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'0.7%',color:'#11BA6C'}">
        Uppdatera företagsinformation
        </div>
        <!-- <div @click ="to_company()" :style="{color:'#11BA6C',cursor:'pointer',textDecoration:'underline',position:'absolute',marginLeft:'57%'}">
        Besök  företagssida
        </div> -->
      </div>
      <div   :style="{marginTop:'0%', height:'auto',textAlign:'left', marginLeft:'0%',width:'80%'}">
          <form name="update_profile">
            <div class="register_header">
              <strong :style="{color:'#11BA6C',fontSize:'24px'}">Existerande användare</strong>
              <div class="" v-if="user_list.length != 0">
                Användare finns. Ny behöver inte skapas<br>
                Användare:
                <div class="" v-for="(user,idx) in user_list" :key="idx">
                  {{user}}
                </div>
              </div>
              <div v-if="user_list.length==0">
                Ingen användare finns. Måste skapas
                </div>
            </div>
            <div class="register_header">
              <strong :style="{color:'#11BA6C',fontSize:'24px'}">Kontoinformation</strong>
              <br>Fyll i kontoinformation så att du kan logga in
            </div>
            <div :style="{width:'90%', marginLeft: '5%',height:'163px',marginTop:'0%', borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%', color:'#11BA6C', fontSize:'18px', paddingTop:'2%'}">Kontoinformation</div>
              <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>Fyll i konto/login information nedan</em>
              </div>
               <input type="text" maxlength="500" placeholder="Användarnamn" class="input_field" @change='check_email_exists()'  v-model.trim="login_email" >
               <input type="password" maxlength="500" placeholder="Lösenord" class="input_field"     v-model="login_password" >
            </div>



            <div :style="{width:'99%', marginLeft: '5%',height:'194px',marginTop:'2%', padding:'3%', borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%', fontSize:'18px', paddingTop:'0%',color:'#11BA6C'}">
                Din företagsbeskrivning
              </div>
              <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>
                  Byt företagsbeskrivning
                </em>
              </div>
                <textarea type="text"   placeholder="Fyll i en kort beskrivning om ditt företag"   class="input_field_l"     v-model="company_info" />
            </div>

            <div :style="{width:'99%', marginLeft: '5%',height:'194px',marginTop:'2%', padding:'3%', borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%', fontSize:'18px', paddingTop:'0%',color:'#11BA6C'}">
                Vad har ni för priser?
              </div>
              <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>
                  Era priser
                </em>
              </div>
                <textarea type="text"   placeholder="Fyll i era priser"   class="input_field_l"     v-model="price" />
            </div>
            <div :style="{width:'99%', marginLeft: '5%',height:'194px',marginTop:'2%', padding:'3%', borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%', fontSize:'18px', paddingTop:'0%',color:'#11BA6C'}">
                Vad erbjuder ni för tjänster
              </div>
              <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>
                  Era tjänster
                </em>
              </div>
                <textarea type="text"   placeholder="Fyll i era tjänster"
                 class="input_field_l"     v-model="our_services" />
            </div>
            <div :style="{width:'99%', marginLeft: '5%',height:'194px',marginTop:'2%', padding:'3%', borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%', fontSize:'18px', paddingTop:'0%',color:'#11BA6C'}">
                Vilka är era öppettider?
              </div>
              <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>
                  Era öppettider
                </em>
              </div>
                <textarea type="text"   placeholder="Fyll i era öppettider"   class="input_field_l"
                  v-model="our_opening_hours" />
            </div>
            <div :style="{width:'99%', marginLeft: '5%',height:'208px',marginTop:'3%',padding:'3%', borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%',color:'#11BA6C', fontSize:'18px',paddingTop:'0%'}">
                Övrig information
              </div>
              <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>
                  Fyll i ytterligare information nedan
                </em>
              </div>

               <input type="text" maxlength="500" placeholder="Företagets hemsida"  class="input_field"     v-model="website" >
               <input type="text" maxlength="500" placeholder="Företagets email"  class="input_field"     v-model="contact_email" >
               <input type="text" maxlength="500" placeholder="Företagets telefon"  class="input_field"     v-model="phone_number" >

               <!-- <input type="text" maxlength="500" placeholder="Address" class="input_field"     v-model="adress" >
               <input type="text" maxlength="500" placeholder="Postkod" class="input_field"    v-model="city_code" >
               <input type="text" maxlength="500" placeholder="Stad" class="input_field"     v-model="city" > -->

               <!-- <input type="text" class="search_ruta_input" v-model="search_lan"
                placeholder="Sök län"
                v-on:keyup="search_lan_func(search_lan)" >
               <div :style="{marginTop:'1%', marginLeft:'3%',width:'90%',maxHeight:'374px', overflowY:'auto', borderStyle:'None', borderWidth:'0.5px'}">
                 <div  :style="{height:'20px', fontSize:'15px',width:'88%', display:'flex'}" v-for="(lan,idx) in this.filtered_lan_list" :key="idx">
                   <input
                     type="checkbox"
                     :id="'lan'+lan.id"
                     :class="lan.checked ? 'checkbox_filled' : 'checkbox_new'"
                     v-model="lan.checked"
                     @click="choose_lan(lan)"    />
                   <label :for="'lan'+lan.id">{{ lan.lan }} </label>
                 </div>
               </div> -->
            </div>
            <div :style="{width:'90%', marginLeft: '5%',height:'auto',marginTop:'3%',paddingBottom:'50px' , borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%',color:'#0A6338', fontSize:'18px',paddingTop:'2%'}">Var finns ni?

              </div>
              <div :style="{height:'30px',fontSize:'13px', marginLeft:'4%',display:'flex', color:'black'}">
                <em>
                  Leverar ni till hela landet så klicka i denna box
                 </em>
                 <input type="checkbox" v-model="online_service" class="checkbox_new" value="">
              </div>
              <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>Ge information om var ni finns (om ni har butiksadress så fyll gärna i det alternativt fyll i de län där ni är verksamma ) </em>
              </div>

              <input type="text" maxlength="500" placeholder="Address" class="input_field"     v-model="adress" >
              <input type="text" maxlength="500" placeholder="Postkod" class="input_field"    v-model="city_code" >
              <!-- <input type="text" maxlength="500" placeholder="Stad" class="input_field"     v-model="city" > -->


              <input type="text" class="search_ruta_input" v-model="search_lan"
               placeholder="Sök stad"
               v-on:keyup="search_city_func(search_lan)" >
              <div :style="{marginTop:'1%', marginLeft:'3%',width:'90%',maxHeight:'154px', overflowY:'auto', borderStyle:'None', borderWidth:'0.5px'}">
                <div  :style="{height:'20px', fontSize:'15px',width:'88%', display:'flex'}"
                v-for="(city,idx) in this.filtered_city_list" :key="idx">
                  <input
                    type="checkbox"
                    :id="'lan'+city.id"
                    :class="city.checked ? 'checkbox_filled' : 'checkbox_new'"
                    v-model="city.checked"
                    @click="choose_city2(city)"
                  />
                  <label :for="'lan'+city.id">{{ city.city }} [ {{city.lan}}] </label>
                </div>
              </div>
              <div :style="{borderStyle:'solid', borderWidth:'1px', borderColor:'black', marginTop:'1%',marginLeft:'4%',width:'90%'}">

              </div>
              <button :style="{marginTop:'2%',marginLeft:'2%'}" @click="add_location()" type="button" name="button">Lägg till </button>

              <div :style="{width:'90%', marginLeft:'4%'}">
                <div  :style="{
                    display: 'flex',
                    width: '90%',
                    justifyContent: 'flexStart',
                    fontWeight: 'bold'  }">
                    <!-- <div class="lead_width_header_loc">Butik</div> -->
                    <div class="lead_width_header_loc">Address</div>
                    <div class="lead_width_header_loc">Postkod</div>
                    <div class="lead_width_header_loc">Kommun</div>
                    <div class="lead_width_header_loc">Län</div>
                </div>
                <div :style="{   display: 'flex',
                    width: '90%',
                    borderBottom: '0.1px grey solid',
                    justifyContent: 'flexStart'}"  v-for="(location,idx) in this.filtered_full_location_list" :key="idx">
                    <!-- <div class="lead_width_left">
                      {{ location.butik }}
                    </div> -->
                    <div class="lead_width_loc">
                      {{ location.adress }}
                    </div>
                    <div class="lead_width_loc">
                      {{ location.city_code }}
                    </div>
                    <div class="lead_width_loc">
                      {{ location.city }}
                    </div>
                    <div class="lead_width_left">
                      {{ location.lan }}
                    </div>
                    <div class="lead_width_loc">
                      <b-icon
                        icon="x-lg"
                        :style="{ marginTop: '1%' }"
                        @click="delete_location(location)"
                      />
                    </div>
                </div>
              </div>
            </div>

            <div :style="{width:'99%',padding:'3%', marginLeft: '5%',height:'659px',marginTop:'3%', borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%', color:'#11BA6C', fontSize:'18px', paddingTop:'0%'}">
                Kategori
              </div>
              <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>
                  Vilken kategori passar din lösning in på? (Markera alla som passar in)
                </em>
              </div>
              <input type="text" class="search_ruta_input" v-model="search_category"
              placeholder="Sök kategori"    v-on:keyup="search_category_func(search_category)"  >

              <div v-if="search_category =='' "
                 :style="{height:'250px',border:0, position:'absolute',borderWidth:'0',
                         overflowY:'auto', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
                         borderRadius:'6px 0px 0px 6px',marginTop:'2%', opacity:'100%',zIndex:13,
                         width:'51%',lineHeight:'14px', backgroundColor:'white',marginLeft:'5%'}">
                         <!-- :class="[ category.category_open    ? 'button_checked' : 'button_unchecked' ]"> -->

                  <div v-for="(category,idx) in huvud_category_list"
                       :key="idx"
                       class="button_unchecked">
                    <div :style="{height:'16px', display:'flex',marginTop:'1%'}"  @click="open_kategori(category.id,category.main_category_svenska)">
                      <div :style="{marginLeft:'1%'}">
                        {{category.main_category_svenska}} {{category.total_category_count}}
                      </div>
                    </div>
                    <div  v-if="category.subcategory_opened"
                       :style="{height:'150px',border:0,
                             borderWidth:'0',overflowY:'scroll', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
                             borderRadius:'0px 6px 6px 0px',marginTop:'1%',borderColor:'black', opacity:'100%',    zIndex:13,
                             width:'55%',backgroundColor:'white',marginLeft:'9%'}">
                      <div  v-for="(category,idx) in category_list"  :style="{display:'flex'}"   :key="idx" >
                        <input   type="checkbox"  :id="'cat'+category.id"
                          :class="category.checked ? 'checkbox_filled' : 'checkbox_new'"
                          v-model="category.checked"    @click="choose_category(category)"  />
                        <label  :style="{textIndent:'5px'}" :for="'cat'+category.id">{{ category.category }} </label>
                      </div>
                    </div>
                  </div>
              </div>



              <!-- <div v-if="search_category !='' ">
                <div :style="{height:'20px', fontSize:'14px',width:'80%', display:'flex'}"
                    v-for="(category,idx) in this.main_category_list" :key="idx">
                  <input
                    type="checkbox"
                    :id="'cat'+category.id"
                    :class="category.checked ? 'checkbox_filled' : 'checkbox_new'"
                    v-model="category.checked"
                    @click="choose_category(category)"
                  />
                  <label  :style="{textIndent:'5px'}" :for="'cat'+category.id">{{ category.category }} </label>
                </div>
              </div> -->


              <div v-if="search_category !='' " :style="{marginTop:'1%', marginLeft:'3%',width:'88%',maxHeight:'520px', overflowY:'auto'}">
                <button v-if="!all_selected_category" type="button" name="button" :style="{ marginLeft:'1.4%',paddingBottom:'0%', borderStyle:'None',color:'white', borderRadius:'8px',backgroundColor:'#11BA6C',marginTop:'0%'}" @click="choose_all_category()">
                  Markera alla
                </button>
                <button v-if="all_selected_category" type="button" name="button" :style="{ marginLeft:'1.4%',paddingBottom:'0%', borderStyle:'None',color:'white', borderRadius:'8px',backgroundColor:'#11BA6C',marginTop:'0%'}" @click="choose_all_category()">
                  Avmarkera alla
                </button>
                <div :style="{height:'5px'}">
                </div>
                <div :style="{height:'20px', fontSize:'14px',width:'80%', display:'flex'}" v-for="(category,idx) in this.filtered_category_list" :key="idx">
                  <input
                    type="checkbox"
                    :id="'cat'+category.id"
                    :class="category.checked ? 'checkbox_filled' : 'checkbox_new'"
                    v-model="category.checked"
                    @click="choose_category(category)"
                  />
                  <label  :style="{textIndent:'5px'}" :for="'cat'+category.id">{{ category.category }} </label>
                </div>
              </div>
            </div>
            <div :style="{width:'90%', marginLeft: '5%',height:'auto',marginTop:'3%', paddingBottom:'20px',borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
              <div :style="{marginLeft:'4%', color:'#0A6338', fontSize:'18px', paddingTop:'2%'}">Märken ni lagar  </div>
              <div :style="{height:'25px',fontSize:'13px', marginLeft:'4%', color:'black'}">
                <em>Vilka märken jobbar ni med? <br> (om du inte hitter det du söker så lägg gärna till det i listan) </em>
              </div>
                <input type="text" class="search_ruta_input" v-model="search_brand"  placeholder="Sök märke"  v-on:keyup="search_brand_func(search_brand)">

                  <button type="button" :style="{color:'white',marginTop:'1%',height:'auto',width:'auto',marginLeft:'4%',borderStyle:'None',backgroundColor:'#0A6338', borderRadius:'8px'}"
                    @click="add_brand(search_brand)">Lägg till "{{search_brand}}"  i listan</button>

                <div v-if="chosen_brands_list.length !=0" :style="{height:'auto',marginTop:'1%',marginLeft:'3%', flexWrap:'wrap',borderRadius:'0px 18px 18px 18px' ,display:'flex',overflowY:'auto' }">
                    <div  v-for="(brands,idx) in this.chosen_brands_list" :key="idx" class="box_flow_key">
                      <div @click="remove_brand(brands)" :style="{ cursor:'pointer',marginLeft:'92%',width:'50px',height:'7px',marginTop:'0%', fontSize:'12px'}">
                        X
                      </div>
                      <div :style="{textAlign:'center',marginTop:'1%'}">
                        {{brands.brand}}
                      </div>
                    </div>
                </div>

                <div :style="{marginTop:'1%', marginLeft:'3%',width:'90%',maxHeight:'230px', overflowY:'auto', borderStyle:'None', borderWidth:'0.5px'}">
                  <div :style="{height:'20px', fontSize:'14px',width:'100%', display:'flex'}"
                        v-for="(brand,idx) in this.filtered_brands_list" :key="idx">
                    <input  type="checkbox"  :id="'key'+brand.id"
                      :class="brand.checked ? 'checkbox_filled' : 'checkbox_new'"
                      v-model="brand.checked" @click="choose_brand(brand)"/>
                    <label :style="{textIndent:'5px'}" :for="'key'+brand.id">{{ brand.brand }}</label>
                  </div>
                </div>
            </div>
          </form>
          <div :style="{width:'99%', padding:'3%',marginLeft: '5%',height:'164px', marginTop:'3%',borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
            <div :style="{marginLeft:'4%', fontSize:'20px', paddingTop:'0%'}">
              Byt logga på ditt företag {{this.image}}
            </div>
            <img id="output"   :src= "get_image(this.company_id,this.image)"   class="show_chosen_image_first"   >
            <label for="upload" :style="{marginLeft:'52%'}" >
              <img :src= "`${publicPath}`+ 'upload.png'"
              class="addphoto_camera"  alt="addphoto_camera" >
            </label>
            <input type="file" multiple="multiple" :style="{marginLeft:'15%'}"
               id="upload" class="custom-file-upload"
                @change="upload_picture">
          </div>
          <div   :style="{width:'99%', padding:'3%',marginLeft: '5%',height:'264px', marginTop:'3%',borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
            <div :style="{marginLeft:'4%',display:'flex', fontSize:'20px', paddingTop:'0%'}">
              <div :style="{width:'270px'}">
                Ladda upp bilder till ditt galleri
              </div>
              <b-icon icon="info-circle"  @click="open_info_box()" :style="{height:'20px',width:'20px', marginTop:'0.5%'}" />
            </div>
            <div :style="{height:'90px',marginLeft:'22%',marginTop:'0%', backgroundColor:'white',padding:'0.5%',zIndex:3,boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)', width:'380px',position:'absolute',fontSize:'12px', color:'black'}" v-if="info_box_opened">
              Då denna hemsida är i en betaversion så är det tyvärr bara möjligt att ladda upp bilder en gång.<br>
              Med andra ord om ni har laddat upp bilder en gång så kommer uppladdningsknappen avaktiveras.
            </div>
            <div :style="{marginLeft:'0%', height:'170px'}">
                  <div :style="{display:'flex', height:'120px',marginTop:'0%', overflowX:'scroll'}">
                    <div  :style="{height:'60px', width:'90px'}">
                      <label for="upload2" :style="{marginLeft:'1%', marginTop:'1%'}" >
                        <img :src= "`${publicPath}`+ 'upload.png'" class="addphoto_camera_gallery"  alt="" >
                      </label>
                      <input type="file" multiple="multiple" :style="{marginLeft:'0%'}"
                        id="upload2" class="custom-file-upload"
                         @change="upload_picture_multiple">
                    </div>
                    <div  v-for="(obj,idx) in temp_gallery_list" :style="{marginLeft:'1%', height:'100%',width:'100px'}" :key="idx">
                      <!-- <b-icon icon="x-lg" @click="remove_from_gallery(obj.id)" :style="{color:'black',zIndex:3,marginTop:'0%',marginLeft:'10%' ,position:'absolute'}" /> -->
                      <img  :id="obj.output"  class="show_chosen_image_first"   alt="">
                    </div>
                    <div  v-for="(obj,idx) in gallery_list" :style="{marginLeft:'1%',height:'70px',width:'70px'}" :key="idx">
                      <b-icon icon="x-lg" @click="remove_from_gallery_loaded(obj.id,obj.image)"
                      :style="{color:'black',marginLeft:'9%', marginTop:'0.5%',zIndex:'3' ,position:'absolute'}" />
                      <img  :src="get_image(company_id,obj.image)"  class="show_chosen_image_first"   alt="">
                    </div>
                  </div>
            </div>
          </div>
          <button @click="update_product()"
          :disabled=" this.login_email=='' &&  this.company_name=='' && this.company_info=='' && this.website ==''   && this.image ==''   "
          :class="[ this.login_email!='' && this.company_name !='' && this.company_info !='' && this.website !=''   && this.image !=''   ? 'register_button_clicked' : 'register_button' ]"   >
            Uppdatera information
          </button>
          <div :style="{height:'90px'}">
          </div>
      </div>
     </div>
  </div>
</template>
<script>

import router from '../router/index'
//import Navigation from '../components/Navigation.vue';
import {API_URL} from '../../config.js'
import axios from 'axios';



export default {
  data:function(){
    return{
      publicPath: process.env.BASE_URL +'',
      user_list:[],
      lead_open_var:false,
      company_info_var:true,
      filter_var:false,
      company_name:"",
      category_open:false,
      leads:[],
      our_opening_hours:'',
      status_list:[],
      online_service:false,
      company_id:0,
      company_info:'',
      website:'',
      contact_email:'',
      phone_number:'',
      adress:'',
      city_code:'',
      image:'',
      city:'',
      chosen_lan_list:[],
      chosen_country:[],
      chosen_category_list:[],

      subcategory_open:false,
      main_category_list:[],

      search_company:'',
      company_list:'',
      search_country:'',
      filtered_country_list:'',
      country_list:[],


      all_selected_category:false,

      products_list:[],

      category_box_opened:false,
      choose_category_list:[],
      filtered_category_list:[],
      category_list:[],
      search_category:'',

      filtered_leads:[],
      selectedFile:"",
      current_image:false,
      description:"",
      län_list:[],
      filtered_lan_list:[],
      huvud_category_list:[],
      search_lan:"",
      loading:false,
      new_password:'',
      current_lead:[],
      phone_number_test:'0739569253',
      chosen_lan:"",
      chosen_lan_id:0,
      login_email:'',
      login_password:'',
      update_profile:'',
      isActive:false,
      price:"",
      our_services:"",
      temp_gallery_list:[],
      gallery_list:[],
      info_box_opened:false,
      chosen_brands_list:[],
      checked_brand_id_list:[],
      search_brand:'',
      filtered_brands_list:[],
      brand_query_max:0,
      brands_list:[],
      location_query_max:0,
      filtered_full_location_list:[],
      full_location_list:[],
      filtered_city_list:[],
      city_list:[]


    }
  },
  methods:{
    choose_main_category(category){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/choose_main_category'+'?category='+category+'&company_id='+this.company_id,
        })
        .then(function(response){
            self.category_list=response.data
        })

    },
    load_brands(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_brands_profile'+'?company_id='+this.company_id,
        })
        .then(function(response){
            self.brands_list = response.data["show_brand_list"]
            self.brand_query_max =response.data["brand_query_max"]

            self.chosen_brands_list =  response.data["chosen_brands_list"]

            self.checked_brand_id_list = response.data["checked_brand_id_list"]

            self.filtered_brands_list= self.brands_list.map(brand =>{
                  if (self.checked_brand_id_list.includes(brand.id) ===true ){
                      brand.checked = true
                  }
                      return brand
              })


      })
    },
    search_brand_func(search_brand){
          this.filtered_brands_list =  this.brands_list.filter(
            brand_object =>  brand_object.brand.toLowerCase().includes(search_brand.toLowerCase())
          )
    },
    add_brand(brand){
      var i =this.brand_query_max
      this.brand_query_max = this.brand_query_max + 1

      var new_brand = {"i":i,"id":this.brand_query_max ,"brand":brand,"checked":true ,'added':true, 'changed':true }
      this.chosen_brands_list.push(new_brand)
      this.brands_list.push(new_brand)
    },
    remove_brand(brands_obj){
      // function removeItem(arr, id){
      //     return arr.filter(item => item.id !== id);
      // }
      // this.brand_query_max = this.brand_query_max - 1
      // this.chosen_brands_list = removeItem(  this.chosen_brands_list, brands_obj.id);
      // //this.brands_list = removeItem(  this.brands_list, brands_obj.id);

      this.choose_brand(brands_obj)
      // this.brands_list = this.brands_list.map(brand => {
      //     if (brand.id == brands_obj.id){
      //         brand.added =  false
      //         brand.changed =  false
      //         brand.checked = false
      //     }
      //         return brand
      //   })
      //
      // this.filtered_brands_list =

    },
    choose_brand(brand_object){
        this.brands_list = this.brands_list.map(brand => {
            if (brand.id == brand_object.id){
                brand.checked = brand.checked === false ? true : false
                brand.changed=true
            }
                return brand
          })

          this.chosen_brands_list = this.brands_list.filter(function (brand) {
            if(brand.checked == true) {
              return brand
            }
          })
    },
    choose_city2(city_object){
      var checked
      if(!city_object.checked){
        checked =true
        this.city = city_object.city
        this.lan = city_object.lan
        this.lan_id = city_object.lan_id


        // this.search_lan = lan.name
        // this.chosen_lan = [{"lan_id":lan.id,"lan":lan.name}]
      }
      else {
        checked=0
//        this.chosen_lan = []
      }
        for (let ii = 0; ii < this.city_list.length;ii++){
          this.city_list[ii].checked =  false
      }
      this.city_list[city_object.i].checked =  checked
    },

    open_categories(){
      for (let ii = 0; ii < this.huvud_category_list.length;ii++){
                this.huvud_category_list[ii].category_open =  false
            }
          if(this.category_open){
            this.subcategory_open = false
            this.category_open = false
          }
          else{
              this.category_open = true
          }
    },
    open_kategori(id,huvud_kategori){
      this.choose_main_category(huvud_kategori)

      for (let ii = 0; ii < this.huvud_category_list.length;ii++){
                this.huvud_category_list[ii].subcategory_opened =  false
            }
          this.huvud_category_list[id].subcategory_opened = true

    },
    get_main_categories(){
        var self = this
        axios({
            method:'get',
            url: API_URL+ '/get_main_categories_profile'+'?company_id='+this.company_id,
          })
          .then(function(response){
              self.huvud_category_list = response.data
          })
    },
    remove_from_gallery(id){
      this.temp_gallery_list = this.temp_gallery_list.filter(x => {
        if (x.id != id){
          return x
        }
      })

      // setTimeout(() => {
        for (var i = 0; i < this.temp_gallery_list.length; i++) {
           //var image = document.getElementById(this.temp_gallery_list['output']);
           document.getElementById(this.temp_gallery_list[i]['output']).src = URL.createObjectURL(this.temp_gallery_list[i]['file']);
        }
      // }, 2000);



      // function removeItem(arr, id){
      //     return arr.filter(item => item.id !== id);
      // }
      // this.temp_gallery_list = this.temp_gallery_list.filter(x => {
      //   console.log("x",x.id, typeof(x.id),typeof(id));
      //     return x.id != id;
      // })
  //    this.temp_gallery_list = removeItem(  this.temp_gallery_list, id)
    },

    remove_from_gallery_loaded(image_id,image_name){
      var self =this
      axios({
         method:'delete',
         url: API_URL+ '/delete_gallery_image',
         data:{  image_id:image_id, image:image_name, company_id:this.company_id}
       })
       .then(function(){
           self.get_gallery_list()
       })
    },
    get_gallery_list(){

      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_gallery_images'+'?company_id='+this.company_id,
        })
        .then(function(response){
          self.gallery_list=response.data
      })

    },

    check_email_exists(){
     var self= this;
     if (this.login_email != ''  ){
       axios({
          method:'get',
          url: API_URL+ '/register_check_email_username'+ '?email='+this.login_email,
        })
        .then(function(response){
          if (response.data =='denied'){
            self.$alert(" Email  already exists, please try again ")
            }
          })
         }
         else{
              self.$alert("Either Email is empty or has the wrong format")
         }
    },
    post_to_S3_with_signed_url(){
      var key ="enjordrepair_user"+this.company_id +"/" +  this.image
      var type =this.selectedFile.type

      var self = this
      var params = {
          Bucket: 'garemotest1',
          Key: key,
          "ContentType":type
      };
     self.get_AWS().getSignedUrl('putObject', params, function (err, url) {
      axios({
          method: "put",
          url,
          data: self.selectedFile,
          headers: {
              "Content-Type": type
          }
      })
      .then(() => {
              //self.current_image =false
          }).catch((err) => {
              console.log('err', err);
          });
      });
    },
    upload_picture_multiple(event){

            this.temp_gallery_list=[]

              for (var i = 0; i < event.target.files.length; i++) {
                var posttype_ending = event.target.files[i].type.split("/")[1]
                if (event.target.files[i].type.split("/")[1]  =='jpeg' && event.target.files[i].name.includes('jpg')){
                  this.gallery_image=event.target.files[i].lastModified +event.target.files[i].name.split("jpg")[0]  + 'jpeg'
                }
                else{
                  this.gallery_image=event.target.files[i].lastModified +event.target.files[i].name.split(posttype_ending.toString())[0]  + posttype_ending.toString()
                }
                  this.temp_gallery_list.push({"id": i , "file":event.target.files[i], "output" :"output"+this.temp_gallery_list.length.toString()+i.toString(),"image": this.gallery_image })
                  this.post_to_S3_with_signed_url_multiple(event.target.files[i],this.gallery_image)
              }

              setTimeout(() => {
                for (var i = 0; i < this.temp_gallery_list.length; i++) {
                   //var image = document.getElementById(this.temp_gallery_list['output']);
                  document.getElementById(this.temp_gallery_list[i]['output']).src = URL.createObjectURL(event.target.files[i]);
                }
              }, 2000);
    },
    upload_picture(event){
              this.selectedFile = event.target.files[0]
              this.isActive=true
              setTimeout(() => {
                var image = document.getElementById('output');
                image.src = URL.createObjectURL(event.target.files[0]);
            }, 1000);
            var posttype_ending = this.selectedFile.type.split("/")[1]
            if (this.selectedFile.type.split("/")[1]  =='jpeg' && this.selectedFile.name.includes('jpg')){
              this.image=this.selectedFile.lastModified +this.selectedFile.name.split("jpg")[0]  + 'jpeg'
            }
            else{
              this.image=this.selectedFile.lastModified +this.selectedFile.name.split(posttype_ending.toString())[0]  + posttype_ending.toString()
            }
    },
    post_to_S3_with_signed_url_multiple(file,image){
      var key ="enjordrepair_user"+this.company_id +"/" +  image
      var type =file.type

      var self = this
      var params = {
          Bucket: 'garemotest1',
          Key: key,
          "Content-Type":type
      };


    self.get_AWS().getSignedUrl('putObject', params, function (err, url) {

      axios({
          method: "put",
          url,
          data: file,
          headers: {
              "Content-Type": type
          }
      })
      .then(() => {

              //self.current_image =false

          }).catch((err) => {
              console.log('err', err);
          });
      });
    },
    removeItem(arr,id){
      // function removeItem(arr, id){
          return arr.filter(item => item.id !== id);
//      }

    },
    check_location(){

      this.filtered_full_location_list = this.full_location_list.filter(function (location) {
        if(location.checked == true) {
          return location
        }
      })

    },

    delete_location(location_obj){

      // if(location_obj.added == true){
      //   this.location_query_max = this.location_query_max - 1
      //
      // }

      this.full_location_list = this.full_location_list.map(location => {
          if (location.id == location_obj.id){
              location.checked =  false
              location.changed = true
              location.added = false
          }
              return location
        })


       // this.filtered_full_location_list = this.removeItem(  this.filtered_full_location_list, location_obj.id);

      this.check_location()

        // var self = this;
        // axios({
        //   method: "delete",
        //   url: API_URL + "/delete_location",
        //   data: {
        //     id: location_obj.id
        //   },
        // }).then(function () {
        //   self.get_company_information();
        // });


      // }

    },

    add_location(){
      var i =this.location_query_max
      this.location_query_max = this.location_query_max + 1

      var new_location = {"i":i,"id":this.location_query_max ,'added':true,'changed':true , 'checked':true ,"adress": this.adress , "city_code":this.city_code, "city":this.city, "lan":this.lan, "lan_id":this.lan_id }
      this.full_location_list.push(new_location)
      this.check_location()




    },
    search_city_func(search_lan){
        this.filtered_city_list =  this.city_list.filter(
          city_object =>  city_object.city.toLowerCase().includes(search_lan.toLowerCase())
        )
    },
    load_cities(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_cities',
        })
        .then(function(response){
            self.city_list = response.data
            self.filtered_city_list =self.city_list
      })
    },


    get_image(id,image){
      if (image){
        var data = 'enjordrepair_user' + id + "/" + image
        return this.$func.get_image(data)
      }
    },
    remove_from_product_list(id){
      // function removeItem(arr, id){
      //     return arr.filter(item => item.id !== id);
      // }
      // this.shown_products_list = removeItem(  this.shown_products_list, id)

      this.products_list = this.products_list.map(product => {
        if (product.id == id){
            product.active = 0
        }
            return product
      })


    },
    update_product(){
      var general = {}
      general["company_name"] = this.company_name
      general["company_info"] = this.company_info
      general["website"] = this.website
      general["contact_email"] = this.contact_email
      general["phone_number"] = this.phone_number
      general["price"] = this.price
      general["our_services"] = this.our_services
      general["our_opening_hours"] = this.our_opening_hours
      general["online_service"] = this.online_service


      // general["adress"] = this.adress
      // general["city_code"] = this.city_code
      // general["city"] = this.city
      general["login_email"] = this.login_email
      general["login_password"] = this.login_password

      this.loading =true
      general["page"] = this.update_profile


      var send_brands_list = this.brands_list.filter(function (brand) {
        if(brand.changed == true) {
          return brand
        }
      })

      var brands ={}
      brands["brands"] = send_brands_list

      console.log("send_brands_list ",send_brands_list);

      var send_locations_list = this.full_location_list.filter(function (location) {
        if(location.changed == true) {
          return location
        }
      })


      var location ={}
      location["adress_list"]  =send_locations_list


      // var lan = {}
      // lan["lan"] = this.chosen_lan_list

      this.chosen_country = this.country_list.filter(function (country_obj) {
        if(country_obj.checked == true) {
          return country_obj
        }
      });

      general["country"] = this.chosen_country
      general["image"] = this.image



      this.chosen_category_list = this.category_list.filter(function (category_obj) {
        if(category_obj.changed == true) {
          return category_obj
        }
      });

      var category = {}
      category["category"] = this.chosen_category_list

          var self = this
          axios({
             method:'put',
             url: API_URL+ '/updateCompany',
             data:{company_id:this.company_id ,general:general,category:category,location:location , brands:brands, gallery_list:this.temp_gallery_list}
           })
           .then(function(){
             self.loading =false
             if(self.isActive){
               self.post_to_S3_with_signed_url()
             }
             self.$alert("Din företagssida har blivit uppdaterad")
           })


    },
    to_company(){
            router.push({ path:"company_page", query:{id:this.company_id} })
    },
    search_category_func(search_category){
        this.filtered_category_list =  this.category_list.filter(
          category_object =>  category_object.category.toLowerCase().includes(search_category.toLowerCase())
        )
    },
    search_lan_func(search_lan){
        this.filtered_lan_list =  this.län_list.filter(
          lan_object =>  lan_object.lan.toLowerCase().includes(search_lan.toLowerCase())
        )
    },
    search_country_func(search_country){
        this.filtered_country_list =  this.country_list.filter(
          country_object =>  country_object.country.toLowerCase().includes(search_country.toLowerCase())
        )
    },
    choose_category(category_object){
      this.category_list = this.category_list.map(category => {
        if (category.id == category_object.id){
            category.checked = category.checked === false ? true : false
              category.changed= true
        }
            return category
      })

    },
    choose_all_category(){
      if(!this.all_selected_category){
        this.all_selected_category = true
        this.category_list = this.category_list.map(category => {
              category.checked = true
              category.changed= true
              return category
        })
      }
      else{
        this.all_selected_category = false
        this.category_list = this.category_list.map(category => {
              category.checked = false
              category.changed= true
              return category
        })
      }
    },
    choose_lan(lan_object){
      this.län_list = this.län_list.map(lan => {
        if (lan.id == lan_object.id){
            lan.checked = lan.checked === false ? true : false
            lan.changed=true
        }
            return lan
      })

      this.chosen_lan_list = this.län_list.filter(function (lan_obj) {
            if(lan_obj.changed == true) {
              return lan_obj
            }
      });

    },
    choose_lan2(lan){
      var checked
      if(!lan.checked){
        checked =true
        this.search_lan = lan.name

        this.chosen_lan = lan.name
        this.chosen_lan_id =lan.id
      }
      else {
        checked=0
        this.chosen_lan = ""
        this.chosen_lan_id=0
      }
        for (let ii = 0; ii < this.län_list.length;ii++){
          this.län_list[ii].checked =  false
      }
      this.län_list[lan.i].checked =  checked


    },

    choose_country(country){
      var checked
      if(!country.checked){
        checked =true
        this.search_country = country.country
        this.chosen_country = [{"id":country.id,"country":country.country}]
      }
      else {
        checked=0
        this.chosen_country = []
      }
        for (let ii = 0; ii < this.country_list.length;ii++){
          this.country_list[ii].checked =  false
      }
      this.country_list[country.i].checked =  checked
    },
    add_more_to_product_list(){
      this.products_list_id = this.products_list_id + 1
      this.products_list.push(
      {
         'id':this.products_list_id,
         'product_id':0,
         'active':1,
         'list': [
              {"name":'', 'label':'Name','label_sve':'Namn'},
              {"name":'', 'label':'Description','descr_sve':'Beskrivning'}
          ]
      }
     )
    },
    get_company_information_categories(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_company_information_categories'+'?company_id='+this.company_id,
        })
        .then(function(response){

          self.category_list = response.data["show_category_list"]
          self.checked_category_id_list =response.data["checked_category_id_list"]
          self.filtered_category_list= self.category_list.map(category =>{
                    if (self.checked_category_id_list.includes(category.id) ===true ){
                        category.checked = true
                    }
                        return category
          })
        })
    },
    get_company_information_lan(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_company_information_lan'+'?company_id='+this.company_id,
        })
        .then(function(response){

          self.län_list = response.data["show_lan_list"]
          self.checked_lan_id_list =response.data["checked_lan_id_list"]

          self.filtered_lan_list= self.län_list.map(lan =>{
                    if (self.checked_lan_id_list.includes(lan.id) ===true ){
                        lan.checked = true
                    }
                        return lan
                })


          self.chosen_lan_list= response.data["show_lan_check_list"]

          // self.chosen_lan_list = self.län_list.filter(function (lan_obj) {
          //       if(lan_obj.checked == true) {
          //         return lan_obj
          //       }
          // });

        })
    },


  get_company_information(){
  //    this.loading = true
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_company_information_update_profile'+'?company_id='+this.company_id,
        })
        .then(function(response){
          self.user_list= response.data["show_user_info_list"]
          if (self.user_list.length==0){
              self.update_profile ='update_profile'
              self.login_email=''
          }
          else{
              self.login_email =self.user_list[0]['name']
              self.update_profile =''

          }

          self.name = response.data["show_company_info"]["name"]
          self.image= response.data["show_company_info"]["image"]

          //self.current_image=true
          self.company_info= response.data["show_company_info"]["about"]
          self.online_service= response.data["show_company_info"]["online_service"]

          self.website= response.data["show_company_info"]["website"]
          self.company_type =response.data["show_company_info"]["company_type"]
          //self.products_list = response.data["show_product_info"]
          self.company_name = response.data["show_company_info"]["name"]

          //self.products_list_id= response.data["show_product_info"]


          self.full_location_list =response.data["show_location_list"]
          self.filtered_full_location_list =self.full_location_list

          self.location_query_max =response.data["location_query_max"]

          self.load_brands()

          self.get_gallery_list()
          self.load_cities()
        //  self.get_company_information_lan()
          // self.chosen_lan_id= response.data["show_company_info"]["lan_id"]
          // self.search_lan =response.data["show_company_info"]["lan"]
          //
          // console.log("self.search_lan",self.search_lan,"self.search_lan",self.search_lan);
          //
          // self.filtered_lan_list = self.län_list.map(lan => {
          //   if (lan.id == self.chosen_lan_id){
          //       lan.checked = true
          //   }
          //       return lan
          // })


          self.contact_email =response.data["show_company_info"]["contact_email"]
          self.phone_number=response.data["show_company_info"]["phone_number"]
          self.price=response.data["show_company_info"]["price"]
          self.our_services=response.data["show_company_info"]["our_services"]
          self.our_opening_hours=response.data["show_company_info"]["our_opening_hours"]


          //self.get_countries()
          self.get_company_information_categories()
        })
    },

    get_countries(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_profile_countries'+'?company_id='+this.company_id,
        })
        .then(function(response){
          self.filtered_country_list= response.data["show_countries_list"]

          self.country_list = self.filtered_country_list.map(country => {
            if (country.id == response.data["checked_country_id"]){
                country.checked = true
            }
                return country
          })


          self.search_country_list = self.country_list.filter(function (company_size_obj) {
            if(company_size_obj.checked == true) {
              return company_size_obj.country
            }
          });
          self.search_country = self.search_country_list[0].country
        })
    },
    load_company_func(search_item){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_companies'+'?search_item='+search_item,
        })
        .then(function(response){
            self.company_list = response.data
        })
    },
    choose_company(company){
      this.search_company = company.name
      this.company_id=company.id
      this.get_company_information(this.company_id)
      this.get_main_categories()

    }

  },
  mounted(){
   window.scrollTo(0, 0);
   this.load_company_func("")
   if(this.image !=''){
     this.selectedFile = event.target.files[0]
     setTimeout(() => {
       var image = document.getElementById('output');
       image.src = URL.createObjectURL(event.target.files[0]);
   }, 1000);

   }

   const x = document.getElementById("demo");
   function getLocation() {
     if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(showPosition);
     } else {
       x.innerHTML = "Geolocation is not supported by this browser.";
     }
   }

   function showPosition(position) {
     var x = "Latitude: " + position.coords.latitude +
     "<br>Longitude: " + position.coords.longitude;
     let latlon = position.coords.latitude + "," + position.coords.longitude;
     console.log(latlon);

     let img_url = "https://maps.googleapis.com/maps/api/staticmap?center="+latlon+"&zoom=14&size=400x300&sensor=false&key=YOUR_KEY";

     document.getElementById("mapholder").innerHTML = "<img src='"+img_url+"'>";
     console.log(x);
   }
  getLocation()


   this.product_list =[
     {  'id':1,
        'list': [{"name":'', 'label':'Name', 'label_sve':'Namn'},
             {"name":'', 'label':'Description', 'descr_sve':'Beskrivning'}]
     }
   ]

   // this.län_list =[
   //   {'i':0, 'id':1, 'name': 'Blekinge län' , 'checked':false  },
   //   {'i':1,'id':2, 'name': 'Dalarnas län'   , 'checked':false},
   //   {'i':2,'id':3, 'name': 'Gotlands län'  , 'checked':false },
   //   {'i':3,'id':4, 'name': 'Gävleborgs län'   , 'checked':false},
   //   {'i':4,'id':5, 'name': 'Hallands län'  , 'checked':false },
   //   {'i':5,'id':6, 'name': 'Jämtlands län'   , 'checked':false},
   //   {'i':6,'id':7, 'name': 'Jönköpings län'   , 'checked':false},
   //   {'i':7,'id':8, 'name': 'Kalmar län'  , 'checked':false },
   //   {'i':8,'id':9, 'name': 'Kronobergs län'  , 'checked':false },
   //   {'i':9,'id':10, 'name': 'Norrbottens län'  , 'checked':false },
   //   {'i':10,'id':11, 'name': 'Skåne län'  , 'checked':false },
   //   {'i':11,'id':12, 'name':  'Stockholms län' , 'checked':false },
   //   {'i':12,'id':13, 'name':  'Södermanlands län' , 'checked':false },
   //   {'i':13,'id':14, 'name':  'Uppsala län' , 'checked':false },
   //   {'i':14,'id':15, 'name':  'Värmlands län' , 'checked':false },
   //   {'i':15,'id':16, 'name':  'Västerbottens län' , 'checked':false },
   //   {'i':16,'id':17, 'name':  'Västernorrlands län'  , 'checked':false},
   //   {'i':17,'id':18, 'name':  'Västmanlands län' , 'checked':false },
   //   {'i':18,'id':19, 'name':  'Västra Götalands län'  , 'checked':false},
   //   {'i':19,'id':20, 'name':  'Örebro län'  , 'checked':false},
   //   {'i':20,'id':21, 'name':  'Östergötlands län' , 'checked':false }
   // ]

  },
  components:{
    //Navigation
    // ,status
  }
}
</script>

<style lang="css" scoped>


.lead_width_loc {
  /* width: 200px; */
  max-width: 190px;
  min-width: 190px;
  white-space: normal;
  word-wrap: break-word;
  height: auto;
  font-size: 18px;
  padding-top: 0.5%;
  padding-left: 1%;

  /* border-left: 1px solid black; */
  text-align: left;
  /*padding-left: 2%; */
  /* border: 0.5px Solid; */
}

.lead_width_header_loc {
  max-width: 190px;
  min-width: 190px;
  padding-left: 1%;
  /* width: 280px; */
  height: 20px;
  font-size: 21px;
  /* text-align: left; */
  /* border-left: 1px solid black; */
  /* border: 0.1px Solid grey; */
}

.lead_width_left {
  /* width: 200px; */
  max-width: 190px;
  min-width: 190px;
white-space: normal;
word-wrap: break-word;
  height: auto;
  font-size: 18px;
  padding-top: 0.5%;
 padding-left: 1%;
  /* border-left: 1px solid black; */
  text-align: left;
  /*padding-left: 2%; */
  /* border: 0.5px Solid; */
}

.grey_button_small{
z-index: 13;
  height:33px;
  font-size: 13px;
    width: 120px;
  padding-top: 2%;
  border-Radius: 8px ;
  border: 1px solid #C7C7C7;
  color:black;
  background: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%)
}

.lead_width_header{
  width: 190px;
  height: 30px;
  /* border: 0.1px Solid grey; */
}

.lead_width{
  width: 190px;
  height: 70px;
  font-size: 13px;
  padding-top: 0.5%;
  /* text-align:left;
  padding-left: 2%; */
  /* border: 0.5px Solid; */

}

.flow{
  height: auto;
  width: 92%;
  display: flex;
  margin-left: 4.2%;
  margin-top: 0%;
  flex-wrap: wrap;
  padding-bottom: 120px;
}

.flowpost{

  height: 152px;
  object-fit: contain;
  min-width: 289px;
  max-width: 289px;
  /* min-width: 142px;
  max-width: 142px; */
  display: flex;
  margin-top: 1%;
  /* padding: 1%; */
  margin-left: 2%;
  padding-bottom: 0px;
  border: 1px solid #C5C2BE;

  border-radius: 14px;

}
.boxes{
  /* height:30px;
  margin-left: 5%;
   display:flex;
   overflow-x: auto;
   scrollbar-width:thin; */

   height:20px;
   padding-bottom: 0.2%;
   margin-left: 2%;
    display:flex;

    margin-top: 1%;

    scrollbar-width:thin;
 width: 90%;

}


.box_header{
  height: 20px;
  /* min-width: 320px; */
  flex: none;
  box-sizing: border-box;
  font-size: 15px;
  font-weight:600;
}

.boxes_key{

  padding-bottom: 1%;
  margin-left: 2%;
  display:flex;
  overflow-x: auto;
  margin-top: 1%;
  scrollbar-width:thin;
  width: 90%;
}


.box_flow_key{
  /* background: linear-gradient(180deg, #459AFB 0%, #2875CE 100%); */
border-radius: 50px;
background-color: #11BA6C;
/* max-width: auto; */
/* min-width: 100px; */
/* flex-direction: row; */
height: auto;
color: white;
padding-left: 0.5%;
padding-right:  0.5%;
margin-left: 1%;
font-size: 13px;
/* width: 120%; */
/* float: left;*/
display: inline-block;
flex: none;
box-sizing: border-box;
}

.main_comp{
    margin-left: 3%;
}
.title{
  width: 85%;
  height: 50px;
  margin-top:3%;
  font-size:25px;
  margin-left:4%;
  color:#11BA6C
}
.box_flow{
  margin-left: 1%;
  color: grey;
  height: auto;
  /* overflow-x: auto; */
/* width: auto; */
display: inline-block;
flex: none;
box-sizing: border-box;
font-size: 16px;
font-size: 15px;
}



body{
  font-family: sans-serif;
}

.register_button{

  color:white;
  margin-left: 25%;
  margin-top: 2%;
  font-size: 22px;
  height:50px;
   width:40%;
   border-style:None;
    border-radius:8px;
    background-color: grey;
}

.register_button_clicked{

  color:white;
  margin-left: 25%;
  margin-top: 2%;
  font-size: 22px;
  height:50px;
   width:40%;
   border-style:None;
    border-radius:8px;
    background-color: #11BA6C
}

.input_field_pass{
  border: 2px solid #59419B6F ;
  color:black;
  margin-top:0%;
  width:88%;
  margin-left: 2%;
   border-radius: 8px;
   height:29px;
}

.input_field{
  border: 2px solid #59419B6F ;
  color:black;
  margin-top:1%;
  width:88%;
  margin-left: 4%;
   border-radius: 8px;

   height:29px;

}
.input_field_l{
  border: 2px solid #59419B6F ;
  color:black;
  margin-top:1%;
  width:88%;
  margin-left: 4%;
   border-radius: 8px;

   height:89px;

}
.grey_button{

  height:60px;
  font-size: 15px;
    width: 170px;
    /* margin-top: 2%; */
  padding-top: 7%;
  /* border-Radius: 8px ; */
  /* border: 1px solid #C7C7C7; */
  color:black;
  background: white;
  /* linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%) */
}

.clicked_button{
  color: white;
  height:60px;
  width: 170px;
      /* margin-top: 2%; */
  font-size: 15px;
  padding-top: 7%;
  border-Radius: 8px ;

  /* border: 1px solid #C7C7C7; */

  background-color: #11BA6C;
}
.register_header{
  width:99%;
  text-align: left;
  font-size:16px;
  margin-top: 0%;
  padding: 3%;
  margin-left: 5%;
  height:110px;
}


.Global_div{
  height: 2550px;
  width: 100%;
  margin-left: 0%;
  /* position: absolute; */
    display: flex;
}
.header_1{
  text-align: center;
  height:112px;
  width: 100%;
    display: flex;
  background-color: #33D075;
  /* position:absolute; */
}
input{
    display: flex;
  width: 100%;
}

h2{
    display: flex;
     text-decoration: underline;
}
.addphoto_camera{
  height: 40px;
  width: 65px;
  margin-left: -23%;
  margin-top: 0%;
  position: absolute;
  cursor: pointer;
  /* background-color: #33D075; */
  color:black
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width:0%;
}



.show_chosen_image_first{
 height: 70px;
  display: flex;
    border-radius: 8px;
  width: 70px;
  margin-left: 5%;
  object-fit: contain;
  position: absolute;
  background-color: white;

}
.addphoto_camera_gallery{
  height: 40px;
  width: 65px;
  margin-left: 2%;
  margin-top: 0%;
  position: absolute;
  cursor: pointer;
  /* background-color: #33D075; */
  color:black
}



.open_gear_box_outside{
  position: fixed;
  height: 1200px;
  opacity: 0.5;
  background: black;
  z-index: 2;
  width: 100%;
  left: 0px;
  top:0px;
}


.checkbox_new{
  margin-left: 1.5%;
  margin-top: 0%;
  /* border-radius:50%; */
  border-style:None;
  height: 18px;
  width: 18px;
  /* background-color: white; */
  /* position: absolute; */
  /* accent-color:white; */
}

.checkbox_filled{
  margin-left: 1.5%;
  margin-top: 0%;
  color: white;
  /* background-color: #33D075; */
  border-radius:50%;
  border-style:None;
  max-height: 18px;
  max-width: 18px;
  accent-color:#11BA6C;
}


.search_ruta_input{
  margin-left: 4%;
  width: 88%;
  height: 24px;
  box-shadow: 0px 3px 12px #00000012;
  margin-top: 2%;
  border-radius: 8px;
  z-index: 4;
  border: 2px solid #59419B6F ;
}

.search_ruta_input__{
  width: 70%;
  height: 90%;
  border-radius: 12px;
  color: #11BA6C;
  border-color: #11BA6C;
border: 2px solid #59419B6F ;
  color: black;
  padding-left: 2%;
  border-style: none;
}
.box_flow_key{
    /* background: linear-gradient(180deg, #459AFB 0%, #2875CE 100%); */
  border-radius: 50px;
  background-color: #11BA6C;
  /* max-width: auto; */
  /* min-width: 100px; */
  /* flex-direction: row; */
  height: auto;
  color: white;
  padding-left: 1%;
  padding-right:  1%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-left: 1%;
  margin-top: 1%;
  font-size: 13px;
  /* width: 120%; */
  /* float: left;*/
  display: inline-block;
  flex: none;
  box-sizing: border-box;
}
.input_box_input_class2{
  width: 90%;
  height: 59px;
  margin-left: 4%;
  border-radius: 12px;
  color: #11BA6C;
  margin-top: 2%;
  border: 4px solid #11BA6C ;
}

.input_box_input{
  width: 70%;
  height: 90%;
  border-radius: 12px;
  color: #11BA6C;
  border-color: #11BA6C;
  color: black;
  padding-left: 2%;
  border-style: none;
}

</style>
